<template>
  <div>
      <div class="recruit-policy-upper">
        <div class="recruit-policy-upper-desc">当社では社員を長期的に育成させ続けたいと考えています。そのため採用活動においては、学部学科や今持っている知識で判断するのではなく、その人のポテンシャルやヒューマンスキルを重視しています。</div>
        <div class="recruit-policy-upper-title">求める人物像</div>
        <div style="margin-top:30px" class="flex" v-for="(item, index) in recruitTextConfig.policyData" :key="index">
            <div v-if="!item.reverse" :style="{background:item.titleColor}" class="recruit-policy-title">{{item.number}}
                <img src="@/assets/images/recruit/img_zhaopin1.png" alt="">
            </div>
            <div :style="{background:item.textColor}" class="recruit-policy-detail" :class="item.reverse?'reverse':''">
                <div class="recruit-policy-detail-header">{{item.title}}</div>
                <div class="recruit-policy-detail-text">{{item.text}}</div>
            </div>
             <div v-if="item.reverse" :style="{background:item.titleColor}" class="recruit-policy-title-reverse">{{item.number}}
                <img src="@/assets/images/recruit/img_zhaopin1.png" alt="">
            </div>
        </div>
      </div>
      <div class="recruit-policy-lower">
          <div class="qa">
              Q&A:
          </div>
          <div class="row">
              <div style="margin-top:40px" v-for="(item, index) in recruitTextConfig.QandA" :key="index" class="col-md-6 col-sm-12 row">
                  <div class="col-1">
                      <img src="@/assets/images/recruit/icon_wenda.png" alt="">
                  </div>
                  <div class="col-9">
                      <div class="Q">{{item.Q}}</div>
                      <div class="A">{{item.A}}</div>
                  </div>
              </div>
          </div>
      </div>
      
  </div>
</template>

<script>
import { recruitTextConfig } from "@/config/Recruit"

export default {
    data(){
        return {
            recruitTextConfig:recruitTextConfig,
        }
    }
}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .flex{
        display: flex;
    }
    .recruit-policy-upper{
        margin-top: 40px;
        padding: 0 calc(100vw/1920*360);
    }
    .recruit-policy-upper-desc{
        font-size:calc(100vw/1920*18);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*36);
        text-align: left;
    }
    .recruit-policy-upper-title{
        font-size:calc(100vw/1920*24);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*33);
        text-align: left;
        margin-top: 30px;
    }
    .recruit-policy-title{
        width: calc(100vw/1920*320);
        height: calc(100vw/1920*230);
        /* background:linear-gradient(180deg,rgba(255,218,103,1) 0%,rgba(233,136,64,1) 100%); */
        border-radius:10px;
        font-size:calc(100vw/1920*80);
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*92);
        padding: calc(100vw/1920*40) 0 0 calc(100vw/1920*40);
        text-align: left;
        z-index: 1;
        overflow: hidden;
    }
    .recruit-policy-title-reverse{
        width: calc(100vw/1920*320);
        height: calc(100vw/1920*230);
        /* background:linear-gradient(180deg,rgba(255,218,103,1) 0%,rgba(233,136,64,1) 100%); */
        border-radius:10px;
        font-size:calc(100vw/1920*80);
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*92);
        padding: calc(100vw/1920*40) 0 0 calc(100vw/1920*40);
        text-align: left;
        z-index: 1;
        overflow: hidden;
        margin-left: -10px;
    }
    .recruit-policy-title img{
        width:calc(100vw/1920*160);
        height:calc(100vw/1920*154);
        position: relative;
        top: calc(100vw/1920*37);
        left: calc(100vw/1920*15);
    }
    .recruit-policy-title-reverse img{
        width:calc(100vw/1920*160);
        height:calc(100vw/1920*154);
        position: relative;
        top: calc(100vw/1920*37);
        left: calc(100vw/1920*15);
    }
    .recruit-policy-detail{
        width: 70%;
        padding: 20px;
        margin-left: -10px;
        z-index: 0;
    }

    .recruit-policy-detail-header{
        font-size:calc(100vw/1920*18);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        text-align: left;
    }
    .recruit-policy-detail-text{
        font-size:calc(100vw/1920*16);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*32);
        text-align: left;
        margin-top: 16px;
    }
    .reverse{
        margin: unset;
    }
    .recruit-policy-lower{
        margin-top: 40px;
        background-color: #F5F5F9;
        padding: 40px calc(100vw/1920*360) 80px calc(100vw/1920*360);
    }
    .qa{
        font-size:calc(100vw/1920*24);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*33);
        text-align: left;
        }
    .Q{
        font-size:calc(100vw/1920*18);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        text-align: left;
    }
    .A{
        margin-top: 20px;
        font-size:calc(100vw/1920*16);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*32);
        text-align: left;
    }
    .recruit-policy-lower img{
        width:calc(100vw/1920*33);
        height:calc(100vw/1920*33);
    }
}
@media screen and (max-width:768px){
    .flex{
        display: flex;
    }
    .recruit-policy-upper{
        margin-top: 40px;
        padding: 20px;
    }
    .recruit-policy-upper-desc{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        text-align: left;
    }
    .recruit-policy-upper-title{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:16px;
        text-align: left;
        margin-top: 30px;
    }
    .recruit-policy-title{
        width: 100px;
        border-radius:10px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(255,255,255,1);
        line-height:20px;
        padding: 40px 0 0 20px;
        text-align: left;
        z-index: 1;
        overflow: hidden;
    }
    .recruit-policy-title-reverse{
        width: 100px;
        border-radius:10px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*92);
        padding: 40px 0 0 20px;
        text-align: left;
        z-index: 1;
        overflow: hidden;
        margin-left: -10px;
    }
    .recruit-policy-title img{
        width:40px;
        height:40px;
        position: relative;
        top: 110px;
        left: 40px;
    }
    .recruit-policy-title-reverse img{
        width:40px;
        height:40px;
        position: relative;
        top: 100px;
        left: 40px;
    }
    .recruit-policy-detail{
        width: 70%;
        padding: 20px;
        margin-left: -10px;
        z-index: 0;
    }

    .recruit-policy-detail-header{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:16px;
        text-align: left;
    }
    .recruit-policy-detail-text{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        text-align: left;
        margin-top: 16px;
    }
    .reverse{
        margin: unset;
    }
    .recruit-policy-lower{
        margin-top: 40px;
        background-color: #F5F5F9;
        padding: 20px;
    }
    .qa{
        font-size:12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:14px;
        text-align: left;
        }
    .Q{
        font-size:12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:14px;
        text-align: left;
    }
    .A{
        margin-top: 20px;
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        text-align: left;
    }
    .recruit-policy-lower img{
        width:calc(100vw/1920*33);
        height:calc(100vw/1920*33);
    }
}
</style>